import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CheckIcon from "@material-ui/icons/Check";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import * as HSCApi from "../apis/HealthStatusCheckApi";
import SERVICE_NAMES from "../enums/SERVICE_NAMES";

export default function HealthStatusCheck() {
  const servicerows = SERVICE_NAMES.map((serviceName) => {
    return { serviceName: serviceName, status: false };
  });

  const [allStatus, setallStatus] = useState(servicerows);

  useEffect(() => {
    testhealthStatus();
  }, []);

  async function testhealthStatus() {
    console.log("test Health Status function");
    const receivedStatus = Array(SERVICE_NAMES.length).fill(false);
    receivedStatus[0] = await HSCApi.checkLoginHealth();
    receivedStatus[1] = await HSCApi.checkDownloadHealth();
    console.log(receivedStatus);
    const newServicerows = servicerows.map((row, index) => {
      return {
        serviceName: row.serviceName,
        status: receivedStatus[index],
      };
    });
    setallStatus(newServicerows);
    return;
  }

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" color="primary" align="center">
          Service Health Dashboard
        </Typography>
      </Grid>
      <Grid item component={Paper} md={6} xs={12} sm={10} lg={6} xl={4}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell fontWeight="fontWeightBold">
                <Typography variant="">Functionality</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="">Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {servicerows.map((row, index) => (
              <TableRow key={row.name}>
                <TableCell xs={7}>
                  <Typography variant="body1" color="primary">
                    {row.serviceName}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {allStatus[index]["status"] ? (
                    <CheckIcon
                      color="primary"
                      data-test="CheckIconPrimary"
                    ></CheckIcon>
                  ) : (
                    <CloseIcon
                      color="secondary"
                      data-test="CheckIconSecondary"
                    ></CloseIcon>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
