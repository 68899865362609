import React, { useEffect, useState } from "react";
import { withAuthenticator } from "aws-amplify-react";
import NavigationBar from "./components/NavigationBar";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { AaisTheme } from "./styles/AaisTheme";
import { AmplifyTheme } from "./styles/AmplifyTheme";
import { AmplifySignUpConfig } from "./config/AmplifySignupConfig";
import { HashRouter, Route, Switch } from "react-router-dom";
import UsersScreen from "./screens/UsersScreen";
import UserScreen from "./screens/UserScreen";
import ScreenNotFoundScreen from "./screens/ScreenNotFoundScreen";
import OrgScreen from "./screens/OrgScreen";
import OrgsScreen from "./screens/OrgsScreen";
import LineScreen from "./screens/LineScreen";
import NotificationsScreen from "./screens/NotificationsScreen";
import RiskAwarenessScreen from "./screens/RiskAwarenessScreen";
import HomeScreen from "./screens/HomeScreen";
import MeContext from "./contexts/MeContext";
import * as UserApi from "./apis/UserApi";
import Dialog from "@material-ui/core/Dialog";
import TermsModal from "./components/TermsModal";
import Footer from "./components/Footer";
import LivrariaScreen from "./screens/LivrariaScreen";
import EmployeeDashboardScreen from "./screens/EmployeeDashboardScreen";
import AccessDeniedScreen from "./screens/AccessDeniedScreen";
import SearchScreen from "./screens/SearchScreen";
import BrowseScreen from "./screens/BrowseScreen";
import BucketsScreen from "./screens/BucketsScreen";
import BucketScreen from "./screens/BucketScreen";
import HealthStatusCheck from "./screens/HealthStatusCheck";

require("./amplify");
function App(props) {
  const [me, setMe] = useState(UserApi.getMe(true));
  const [showTerms, setShowTerms] = useState(false);

  //useReportPageView();

  useEffect(() => {
    UserApi.getMe().then((user) => {
      setMe(user);
      if (!user.termsAccepted) setShowTerms(true);
    });
  }, []);

  useEffect(() => {
    if (me) {
      window.gtag("config", `${process.env.REACT_APP_GA}`, {
        user_id: me.userId,
      });
      window.gtag("set", { user_id: me.userId });
    }
  }, [me]);

  async function acceptTerms() {
    setShowTerms(false);
    const updatedMe = await UserApi.acceptTerms(me.userId);
    setMe(updatedMe);
  }

  return (
    <MuiThemeProvider theme={createMuiTheme(AaisTheme)}>
      <HashRouter>
        <MeContext.Provider value={me}>
          <NavigationBar />
          <Switch>
            <Route exact path="/" component={HomeScreen} />
            <Route exact path="/orgs" component={OrgsScreen} />
            <Route exact path="/orgs/:orgId" component={OrgScreen} />
            <Route
              exact
              path="/employees"
              component={EmployeeDashboardScreen}
            ></Route>
            <Route exact path="/utils/livraria" component={LivrariaScreen} />
            <Route exact path="/risk" component={RiskAwarenessScreen} />
            <Route exact path="/data" component={BucketsScreen} />
            <Route
              exact
              path="/data/buckets/:bucketId"
              component={BucketScreen}
            />
            <Route
              exact
              path="/notifications"
              component={NotificationsScreen}
            />
            <Route exact path="/lines/:lineKey" component={LineScreen} />
            <Route exact path="/users" component={UsersScreen} />
            <Route exact path="/search" component={SearchScreen} />
            <Route exact path="/health-check" component={HealthStatusCheck} />

            <Route exact path="/browse" component={BrowseScreen} />
            <Route path="/users/:userId" component={UserScreen} />
            <Route exact path="/403" component={AccessDeniedScreen} />
            <Route component={ScreenNotFoundScreen} />
          </Switch>
          <Footer />
        </MeContext.Provider>
        <Dialog
          open={showTerms}
          scroll="paper"
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >
          <TermsModal handleClose={acceptTerms} />
        </Dialog>
      </HashRouter>
    </MuiThemeProvider>
  );
}

export default withAuthenticator(
  App,
  false,
  [],
  undefined,
  AmplifyTheme,
  AmplifySignUpConfig
);
