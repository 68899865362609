/**
 * @description This is Loading Component. This component can be used as a child by
 *  any component which is fetching data from backend at the mounting stage of it.
 * @author Reza Khazali <rezak@aaisonline.com>
 * @since 1.0.0
 * @module components/LoadingComponent
 */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Link } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

/**
 * Checking if the number trying to fetch the data reaches to almost
 * the times it would try in 3 minutes or 12*3=36
 */

const MAX_FAILURE_COUNT = 36;
const FAILURE_STATUS_CODES = new Set([400, 404, 500]);

export function LoadingComponent(props) {
  const handleClose = () => {};

  useEffect(() => {
    loadBucketsOnce(); // loadBucketsOnce() tries to load from the
    // given function once, if not received the result will try
    // loading in a setInterval loop.
  }, []);

  async function loadBucketsOnce() {
    props.handleStartLinearLoading(true);

    const firstResult = await props.auxFunction();
    console.log("tmp_res for first time", firstResult);
    const statusCode = firstResult?.response?.status;

    if (
      firstResult !== undefined &&
      firstResult !== null &&
      statusCode !== 503 &&
      !FAILURE_STATUS_CODES.has(statusCode)
    ) {
      props.parentSetterHandling(firstResult);
      props.handleStartLoading(false);
      props.handleStartLinearLoading(false);
    } else {
      loadBuckets();
    }
    // loadBuckets();
  }

  function loadBuckets() {
    let counter = 0;
    let apiResult;
    let statusCode;
    props.handleStartLoading(true);

    const timer = setInterval(async () => {
      if (
        counter >= MAX_FAILURE_COUNT ||
        // (statusCode !== undefined && statusCode !== 200) ||
        FAILURE_STATUS_CODES.has(statusCode)
      ) {
        props.handleFailingCase();
        clearInterval(timer);
        console.log("failed to fetch results", apiResult);
        return;
      }
      counter++;
      console.log("numbers of try to fetch:", counter);
      console.log("****:", apiResult);
      apiResult = await props.auxFunction();
      statusCode = apiResult?.response?.status;
      if (
        apiResult !== undefined &&
        apiResult !== null &&
        statusCode !== 503 &&
        !FAILURE_STATUS_CODES.has(statusCode)
      ) {
        props.parentSetterHandling(apiResult);
        props.handleStartLoading(false);
        props.handleStartLinearLoading(false);
        clearInterval(timer);
      } else {
        console.log("it is still loading...");
        props.handleStartLoading(true);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={props.open || props.failedLoading}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        {props.open ? (
          <Grid>
            <DialogTitle id="max-width-dialog-title">Loading</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Alert severity="info">Page is loading. Please wait.</Alert>
                </Grid>
                <Grid item xs={12}>
                  <LinearDeterminate></LinearDeterminate>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions></DialogActions>
          </Grid>
        ) : (
          ""
        )}

        {props.failedLoading ? (
          <Grid>
            <DialogTitle id="max-width-dialog-title">
              Loading failed
            </DialogTitle>
            <DialogContent>
              <Alert severity="error">
                Loading the page failed. Please try again later. just for test
                xyzvasdf
              </Alert>
            </DialogContent>
            <DialogActions justifyContent="center" textAlign="center">
              <Button
                onClick={handleClose}
                color="primary"
                display="flex"
                justifyContent="center"
                textAlign="center"
                alignItems="center"
              >
                <Link href={`#`} textAlign="center">
                  GO HOME
                </Link>
              </Button>
            </DialogActions>
          </Grid>
        ) : (
          ""
        )}
      </Dialog>
    </React.Fragment>
  );
}

LoadingComponent.propTypes = {
  auxFunction: PropTypes.func.isRequired,
  handleStartLoading: PropTypes.func.isRequired,
  handleFailingCase: PropTypes.func.isRequired,
  parentSetterHandling: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  failedLoading: PropTypes.bool.isRequired,
};

LoadingComponent.defaultProps = {
  auxInput: "",
  open: true,
  failedLoading: false,
};

export function LinearDeterminate() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 3000) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 3000);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <LinearProgress variant="determinate" value={progress} />;
}
