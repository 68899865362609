/**
 * @description This is the Bucket detailes and record files screen.
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @author Reza Khazali <rezak@aaisonline.com>
 * @since 1.0.0
 * @module screens/BucketScreen
 */
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import * as MdsApi from "../apis/MdsApi";
import LinearProgress from "@material-ui/core/LinearProgress";
import Container from "@material-ui/core/Container";
import { Button, Paper, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import GenericSingleTextDialog from "../components/GenericSingleTextDialog";
import * as UserApi from "../apis/UserApi";
import BucketFileList from "../components/BucketFileList";
import { LoadingComponent } from "../components/Loading";

const DEFAULT_TIMEOUT = 1000 * 5;

export default function BucketScreen(props) {
  const classes = useStyles();
  const [loadingModal, setLoadingModal] = useState(false);
  const [linearLoading, setlinearLoading] = useState(false);

  const { bucketId } = useParams();
  const [bucket, setBucket] = useState();
  const [bucketFiles, setBucketFiles] = useState();
  const [createdUser, setCreatedUser] = useState();
  const [updatedUser, setUpdatedUser] = useState();
  const [isNameDialogOpen, setIsNameDialogOpen] = useState(false);
  const [failedLoading, setFailedLoading] = useState(false);
  const uploadRef = useRef();

  //used for the polling of the file status
  const timeout = useRef(DEFAULT_TIMEOUT);
  const [randomNumber, setRandomNumber] = useState(Math.random());

  useEffect(() => {
    getBucketFiles();
  }, [bucketId]);

  useEffect(() => {
    if (bucket?.createdBy) {
      UserApi.get(bucket.createdBy).then((user) => setCreatedUser(user));
    }
    if (bucket?.updatedBy) {
      UserApi.get(bucket.updatedBy).then((user) => setUpdatedUser(user));
    }
  }, [bucket]);

  //exponential backoff to check for changes
  useEffect(() => {
    const timer = setTimeout(() => {
      setRandomNumber(Math.random());
      getBucketFiles();
    }, timeout.current);

    return () => clearTimeout(timer);
  }, [randomNumber]);

  async function getBucket() {
    setLoadingModal(true);
    const aBucket = await MdsApi.getBucket(bucketId);
    setBucket(aBucket);
    setLoadingModal(false);
  }

  function handleStartLoading(turnOn) {
    if (turnOn) setLoadingModal(true);
    else setLoadingModal(false);
  }

  function handleStartLinearLoading(turnOn) {
    if (turnOn) setlinearLoading(true);
    else setlinearLoading(false);
  }

  function handleSettingBuckets(tmpBucket) {
    setBucket(tmpBucket);
  }

  function handleFailingCase() {
    setFailedLoading(true);
    setLoadingModal(false);
  }

  async function auxFunction() {
    return await MdsApi.getBucket(bucketId);
  }

  async function getBucketFiles() {
    try {
      const aBucketFiles = await MdsApi.getBucketFiles(bucketId);
      setBucketFiles(aBucketFiles);
    } catch (e) {
      console.log("something went wrong getting bucket files", e);
    }
  }

  function resetPolling() {
    timeout.current = DEFAULT_TIMEOUT; //reset the time everytime you upload a new file
    setRandomNumber(Math.random());
  }

  async function renameBucket(name) {
    setLoadingModal(true);

    const aBucket = await MdsApi.updateBucket(bucketId, name);
    if (aBucket) getBucket();

    setIsNameDialogOpen(false);
    setLoadingModal(false);
  }

  function handleFileUploadProgress(e) {
    console.log("upload progress", e);
  }

  function uploadFileClicked() {
    uploadRef.current.click();
  }

  async function handleFileChanged(e) {
    setLoadingModal(true);

    const file = uploadRef.current.files[0];

    //reset the file so that you can upload the same file again
    e.target.value = null;

    const bucketFile = await MdsApi.createBucketFile(bucketId, file.name);
    await getBucketFiles(); //refresh the bucket file right away

    const data = await MdsApi.getPresignedURL(
      bucketId,
      bucketFile.bucketFileId,
      file.name
    );

    const formData = new FormData();
    formData.append("Content-Type", file.type);
    Object.entries(data.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append("file", file); //must be the last one

    try {
      //upload the file
      const request = new XMLHttpRequest();
      request.open("POST", data.url);

      // upload progress event
      request.upload.addEventListener("progress", async (event) => {
        // upload progress as percentage
        const progress = (event.loaded / event.total) * 100;
        await MdsApi.setBucketFileProgress(
          bucketFile.bucketId,
          bucketFile.bucketFileId,
          progress
        );
      });

      request.addEventListener("load", async (event) => {
        console.log("load", event);
        // HTTP status message (200, 404 etc)
        console.log(request.status);
        if (request.status >= 400) {
          alert(`Upload Failed for ${bucketFile.bucketFileId}`);
          await MdsApi.setBucketFileProgress(
            bucketFile.bucketId,
            bucketFile.bucketFileId,
            -1
          );
        }
      });

      request.addEventListener("abort", async (event) => {
        console.log("abort", event);
        await MdsApi.setBucketFileProgress(
          bucketFile.bucketId,
          bucketFile.bucketFileId,
          -1
        );
      });

      request.addEventListener("loadstart", (event) => {
        console.log("loadstart", event);
      });

      request.addEventListener("loadend", (event) => {
        console.log("loadend", event);
      });

      request.addEventListener("error", async (event) => {
        console.log("error", event);
        await MdsApi.setBucketFileProgress(
          bucketFile.bucketId,
          bucketFile.bucketFileId,
          -1
        );
      });

      // send POST request to server
      request.send(formData);

      resetPolling();
    } catch (error) {
      console.error("error uploading file", error);
    } finally {
      setLoadingModal(false);
    }
  }

  return (
    <React.Fragment>
      <Container className={classes.container} maxWidth={false}>
        {linearLoading && <LinearProgress />}
        {bucket && (
          <Paper className={classes.paper} elevation={3}>
            <Grid container spacing={2}>
              <Grid item container xs={12} sm={6} md={4} lg={3} xl={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    color="primary"
                    className={classes.bucketName}
                    onClick={() => setIsNameDialogOpen(true)}
                  >
                    {bucket.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" className={classes.titles}>
                    Bucket Name
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} md={4} lg={3} xl={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {new Date(bucket.createdDate).toLocaleDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" className={classes.titles}>
                    Created
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} md={4} lg={3} xl={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {createdUser ? createdUser.email : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" className={classes.titles}>
                    Created By
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} md={4} lg={3} xl={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {new Date(bucket.updatedDate).toLocaleDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" className={classes.titles}>
                    Last Updated
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={6} md={4} lg={3} xl={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {updatedUser ? updatedUser.email : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" className={classes.titles}>
                    Last Updated By
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Container>
      <Container className={classes.container} maxWidth={false}>
        <Paper className={classes.paper} elevation={3}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5">
                Files{" "}
                {bucketFiles ? `(${bucketFiles?.totalNumberOfItems})` : ""}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={uploadFileClicked}
              >
                Upload File
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <BucketFileList
          bucketFiles={bucketFiles?.items ? bucketFiles.items : []}
        />
      </Container>
      <LoadingComponent
        open={loadingModal}
        failedLoading={failedLoading}
        bucket={true}
        auxFunction={auxFunction}
        auxInput={bucketId}
        parentSetterHandling={handleSettingBuckets}
        handleFailingCase={handleFailingCase}
        handleStartLoading={handleStartLoading}
        handleStartLinearLoading={handleStartLinearLoading}
      ></LoadingComponent>
      <input
        type="file"
        hidden
        ref={uploadRef}
        onChange={handleFileChanged}
        onProgress={handleFileUploadProgress}
        accept=".txt"
      ></input>
      {isNameDialogOpen && (
        <GenericSingleTextDialog
          title="Update Bucket Name"
          label="Bucket Name"
          value={bucket.name}
          placeholder="2020-Q4 or 2020-01"
          close={() => setIsNameDialogOpen(false)}
          submitButtonText="Update"
          onSubmit={renameBucket}
        ></GenericSingleTextDialog>
      )}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
  },
  titles: {
    color: theme.palette.grey[500],
  },
  bucketName: {
    cursor: "pointer",
  },
}));
