import Amplify, { Auth, Analytics } from "aws-amplify";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AMPLIFY_AUTH_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AMPLIFY_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_ID,
    userPoolWebClientId:
      process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
    oauth: {
      label: "AAIS Employees",
      domain: process.env.REACT_APP_AMPLIFY_AUTH_OAUTH_DOMAIN,
      redirectSignOut:
        process.env.REACT_APP_AMPLIFY_AUTH_OAUTH_REDIRECT_SIGN_OUT,
      redirectSignIn: process.env.REACT_APP_AMPLIFY_AUTH_OAUTH_REDIRECT_SIGN_IN,
      responseType: "code",
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
        "name",
        "given_name",
        "family_name",
      ],
    },
  },
  API: {
    endpoints: [
      {
        name: "ORG",
        region: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_REGION,
        endpoint: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_ORG_URL,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          };
        },
      },
      {
        name: "USER",
        region: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_REGION,
        endpoint: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_USER_URL,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          };
        },
      },
      {
        name: "ASSET",
        region: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_REGION,
        endpoint: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_ASSET_URL,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          };
        },
      },

      {
        name: "MDS",
        region: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_REGION,
        endpoint: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_MDS_URL,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          };
        },
      },
      {
        name: "HEALTHCHECK",
        region: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_REGION,
        endpoint: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_HEALTHCHECK_URL,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          };
        },
      },
    ],
  },
  Analytics: {
    AWSPinpoint: {
      appId: process.env.REACT_APP_AMPLIFY_PINPOINT_APP_ID,
      region: process.env.REACT_APP_AMPLIFY_API_ENDPOINT_REGION,
      mandatorySignIn: true,
    },
  },
});

Analytics.autoTrack("pageView", {
  enable: true,
  eventName: "pageView",
  attributes: async () => {
    return {
      userId: (await Auth.currentSession()).idToken.payload.sub,
    };
  },
  type: "SPA",
  provider: "AWSPinpoint",
  getUrl: () => {
    return (
      window.location.origin + window.location.pathname + window.location.hash
    );
  },
});

Analytics.autoTrack("event", {
  enable: true,
  events: ["click"],
  // OPTIONAL, the prefix of the selectors, by default is 'data-amplify-analytics-'
  // in order to avoid collision with the user agent, according to https://www.w3schools.com/tags/att_global_data.asp
  // always put 'data' as the first prefix
  selectorPrefix: "data-aws-pp-",
  // OPTIONAL, the service provider, by default is the AWS Pinpoint
  provider: "AWSPinpoint",
  attributes: async () => {
    return {
      userId: (await Auth.currentSession()).idToken.payload.sub,
      hash: window.location.hash,
    };
  },
});
