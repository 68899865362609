import React, { useContext, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import SummaryBar from "../components/SummaryBar";
import Grid from "@material-ui/core/Grid";
import MeContext from "../contexts/MeContext";
import Typography from "@material-ui/core/Typography";
import LineMenu from "../components/LineMenu";
import { Container } from "@material-ui/core";
import MyRecentProductsCard from "../components/MyRecentProductsCard";
import { useLocation } from "react-router-dom";
import GenericRiskCard from "../components/GenericRiskCard";
import * as userUtil from "../util/userUtil";
import MyOrgsCard from "../components/MyOrgsCard";
import BrowseAllProductsCard from "../components/BrowseAllProductsCard";
import StatisticalReportingCard from "../components/StatisticalReportingCard";

function HomeScreen(props) {
  const { classes } = props;
  const me = useContext(MeContext);
  const location = useLocation();

  useEffect(() => {
    console.log("home screen loaded", location);
    window.gtag("config", `${process.env.REACT_APP_GA}}`, {
      page_title: "Home Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
    });
  }, [location]);

  function getTitle() {
    if (me && me.firstName) return `Welcome, ${me.firstName}`;
    else if (me && me.email) return `Welcome, ${me.email}`;
    return `Welcome`;
  }

  function getWelcome() {
    return (
      <React.Fragment>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.welcome}
          justify="space-between"
        >
          <Grid item>
            <Typography variant="h2">
              Welcome to AAISdirect xyz test message
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">
              Currently a preview xyz 222 test message
            </Typography>
          </Grid>
          <Grid item>
            <Typography>AAIS Copyright {new Date().getFullYear()}</Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <SummaryBar title={getTitle()}>{getWelcome()}</SummaryBar>
        <Container maxWidth="xl">
          <LineMenu></LineMenu>
          <Grid
            container
            className={classes.dashboard}
            spacing={2}
            justify="center"
          >
            <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
              <BrowseAllProductsCard />
            </Grid>
            {me?.userId && (
              <React.Fragment>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <MyRecentProductsCard
                    userId={me.userId}
                  ></MyRecentProductsCard>
                </Grid>
                {!userUtil.isEmployee(me) && (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <MyOrgsCard></MyOrgsCard>
                  </Grid>
                )}
              </React.Fragment>
            )}
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
              <GenericRiskCard
                title="Risk Awareness Service"
                src="https://aais.maps.arcgis.com/apps/webappviewer/index.html?id=1c17347d0c4a4d649d56ce8601db876e"
                callToActionText="See more"
                callToActionHref="#/risk"
              ></GenericRiskCard>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <StatisticalReportingCard />
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(8),
  },
  grid: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  welcome: {
    paddingTop: 20,
    height: "calc(100vh - 141px)",
    textAlign: "center",
  },
  dashboard: {
    paddingTop: theme.spacing(2),
  },
});

export default withStyles(styles, { withTheme: true })(HomeScreen);
