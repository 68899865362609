/**
 * @description MDSApi Module. This will have all the API's needed
 * to interact with the backend API's for the Member data submissions
 * @module apis/MDSApi
 * @since 1.0.0
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @public
 */

import { API } from "aws-amplify";

/**
 * @description Creates a bucket
 * @since 1.0.0
 * @author Findlay Clarke <findlayc@aaisonline.com>
 */
export async function createBucket(name) {
  const path = `/v1/buckets`;
  const body = {
    name: name,
  };

  const init = { body };

  try {
    return await API.post("MDS", path, init);
  } catch (error) {
    console.log(error);
  }
}

/**
 * @description Get all the buckets
 *
 */
export async function listBuckets() {
  const path = `/v1/buckets`;

  try {
    return await API.get("MDS", path);
  } catch (error) {
    console.log(error);
  }
}

export async function getBucket(bucketId) {
  if (!bucketId) throw Error("bucketId is required");

  const path = `/v1/buckets/${bucketId}`;

  try {
    return await API.get("MDS", path);
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function updateBucket(bucketId, name) {
  const path = `/v1/buckets/${bucketId}`;
  const body = {
    name: name,
  };

  const init = { body };

  try {
    return await API.put("MDS", path, init);
  } catch (error) {
    console.log(error);
  }
}

export async function createBucketFile(bucketId, filename) {
  if (!bucketId) throw Error("bucketId is required");
  if (!filename) throw Error("filename is required");

  const path = `/v1/buckets/${bucketId}/files`;
  const body = {
    filename: filename,
  };

  const init = { body };

  try {
    return await API.post("MDS", path, init);
  } catch (error) {
    console.log(error);
  }
}

export async function getBucketFiles(bucketId) {
  if (!bucketId) throw Error("bucketId is required");

  const path = `/v1/buckets/${bucketId}/files`;
  return await API.get("MDS", path);
}

export async function getBucketFilesDataQuality(bucketId, bucketFileId) {
  if (!bucketId) throw Error("bucketId is required");

  const path = `/v1/buckets/${bucketId}/files/${bucketFileId}/data-quality`;

  try {
    return await API.get("MDS", path);
  } catch (error) {
    console.log(error);
  }
}

export async function setBucketFileProgress(bucketId, bucketFileId, progress) {
  if (!bucketId || !bucketFileId || !progress)
    throw Error("bucketId is required");

  const path = `/v1/buckets/${bucketId}/files/${bucketFileId}/progress`;

  const body = {
    progress,
  };

  const init = { body };

  try {
    return await API.put("MDS", path, init);
  } catch (error) {
    console.log(error);
  }
}

export async function getPresignedURL(bucketId, bucketFileId, filename) {
  if (!bucketId) throw Error("bucketId is required");
  if (!filename) throw Error("filename is required");
  if (!bucketFileId) throw Error("bucketFileId is required");

  const path = `/v1/buckets/${bucketId}/files/${bucketFileId}`;
  const body = {
    filename: filename,
    bucketFileId: bucketFileId,
  };

  const init = { body };

  try {
    return await API.patch("MDS", path, init);
  } catch (error) {
    console.log(error);
  }
}

export async function getBucketFileExportSignedUrl(bucketId, bucketFileId) {
  if (!bucketId || !bucketFileId) throw Error("missing required params");

  const path = `/v1/buckets/${bucketId}/files/${bucketFileId}/exports/summary`;

  try {
    return await API.post("MDS", path);
  } catch (error) {
    console.log(error);
  }
}
