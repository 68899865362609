import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useLocation, useHistory } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import * as AssetApi from "../apis/AssetApi";
import "../styles/Search.css";
import NotCurrent from "@material-ui/icons/NotInterested";
import CurrentIcon from "@material-ui/icons/Check";
import FormIcon from "@material-ui/icons/ListAlt";
import RuleIcon from "@material-ui/icons/Gavel";
import RatingIcon from "@material-ui/icons/StarRate";
import LossCostIcon from "@material-ui/icons/AttachMoney";
import StatesHelperIcon from "@material-ui/icons/ArrowForwardIos";
import Tooltip from "@material-ui/core/Tooltip";
import LINES from "../enums/LINES";
import STATES from "../enums/STATES";
import PACKAGE_TYPES from "../enums/PACKAGE_TYPES";
import AAISPDFViewerDialog from "../components/AAISPDFViewerDialog";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DeleteIcon from "@material-ui/icons/HighlightOffTwoTone";
import ClearIcon from "@material-ui/icons/Clear";
import uuid from "uuid/v4";
import SupportingDocIcon from "@material-ui/icons/AttachFile";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SearchScreen(props) {
  const classes = useStyles();

  const initalQuery = useQuery().get("q");
  const initialSearchId = useQuery().get("sid");
  const initialFilterStatus = useQuery().get("filterStatus");
  const initialFilterType = useQuery().get("filterType");
  const initialFilterProductLine = useQuery().get("filterProductLine");
  const initialFilterState = useQuery().get("filterState");
  const history = useHistory();

  const [term, setTerm] = useState(initalQuery ? initalQuery : "");
  const [searchId] = useState(initialSearchId ? initialSearchId : uuid());
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState(
    initialFilterStatus && initialFilterStatus !== "null"
      ? initialFilterStatus
      : ""
  );
  const [filterType, setFilterType] = useState(
    initialFilterType && initialFilterType !== "null" ? initialFilterType : ""
  );
  const [filterProductLine, setFilterProductLine] = useState(
    initialFilterProductLine && initialFilterProductLine !== "null"
      ? initialFilterProductLine
      : ""
  );
  const [filterState, setFilterState] = useState(
    initialFilterState && initialFilterState !== "null"
      ? initialFilterState
      : ""
  );
  const [previewItem, setPreviewItem] = useState();

  useEffect(() => {
    performSearch();
  }, []);

  useEffect(() => {
    performSearch();
  }, [filterStatus, filterType, filterProductLine, filterState, initalQuery]);

  function getCurrentFilters() {
    const chips = [];
    const fakeItem = { _source: {} };
    //status
    if (filterStatus) {
      fakeItem._source.status_s = filterStatus;
      chips.push(getStatus(fakeItem, true));
    }
    if (filterType) {
      fakeItem._source.packageType_s = filterType;
      chips.push(getType(fakeItem, true));
    }

    if (filterProductLine) {
      fakeItem._source.productLines_ss = filterProductLine;
      chips.push(getProductLines(fakeItem, true));

      if (filterState) {
        fakeItem._source[`aais_${filterProductLine}_Jur_ss`] = filterState;
        chips.push(getStates(fakeItem, true));
      }
    }

    return chips;
  }

  function handlekeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      performSearch();
    }
  }

  async function performSearch() {
    if (
      !term &&
      !filterStatus &&
      !filterType &&
      !filterProductLine &&
      !filterState
    ) {
      setResults(null);
      return;
    }

    setLoading(true);
    let filters;
    let filterUrl = "";

    if (filterStatus || filterType || filterProductLine || filterState) {
      filters = {
        status_s: filterStatus,
        packageType_s: filterType,
        productLine: filterProductLine,
        state: filterState,
      };
      filterUrl = `&filterStatus=${filterStatus}&filterType=${filterType}&filterProductLine=${filterProductLine}&filterState=${filterState}`;
    }
    const tempResults = await AssetApi.search(term, filters);
    setResults(tempResults);
    setLoading(false);

    history.replace(`?sid=${searchId}&q=${encodeURI(term)}${filterUrl}`);

    AssetApi.searchSendAnalytics(searchId, term, filters);
  }

  function convertMsToSeconds(time) {
    if (Number.isNaN(time)) return "Error";

    return (time / 1000).toFixed(2);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (type, event) => {
    setAnchorEl({ type, target: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function getFilterBar() {
    return (
      <React.Fragment>
        <Button color="primary" onClick={(e) => handleClick("status", e)}>
          Status
        </Button>
        <Menu
          anchorEl={
            anchorEl && anchorEl.type === "status" ? anchorEl.target : null
          }
          open={anchorEl && anchorEl.type === "status" ? true : false}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setFilterStatus("Externally Approved");
              handleClose();
            }}
            selected={filterStatus === "Externally Approved"}
          >
            <ListItemIcon>
              <CurrentIcon className={classes.currentIcon}></CurrentIcon>
            </ListItemIcon>
            Current
          </MenuItem>
          <MenuItem
            onClick={() => {
              setFilterStatus("Superseded");
              handleClose();
            }}
            selected={filterStatus === "Superseded"}
          >
            <ListItemIcon>
              <NotCurrent className={classes.notCurrentIcon}></NotCurrent>
            </ListItemIcon>
            Not Current
          </MenuItem>
        </Menu>
        <Button color="primary" onClick={(e) => handleClick("packageType", e)}>
          Type
        </Button>
        <Menu
          anchorEl={
            anchorEl && anchorEl.type === "packageType" ? anchorEl.target : null
          }
          open={anchorEl && anchorEl.type === "packageType" ? true : false}
          onClose={handleClose}
        >
          {Object.keys(PACKAGE_TYPES).map((type) => {
            return (
              <MenuItem
                key={type}
                onClick={() => {
                  setFilterType(PACKAGE_TYPES[type].packageType_s);
                  handleClose();
                }}
                selected={filterType === PACKAGE_TYPES[type].packageType_s}
              >
                <ListItemIcon>
                  {getTypeIcon(PACKAGE_TYPES[type].packageType_s)}
                </ListItemIcon>
                {PACKAGE_TYPES[type].title}
              </MenuItem>
            );
          })}
        </Menu>
        <Button color="primary" onClick={(e) => handleClick("product", e)}>
          Product
        </Button>
        <Menu
          anchorEl={anchorEl?.type === "product" ? anchorEl.target : null}
          open={anchorEl?.type === "product" ? true : false}
          onClose={handleClose}
        >
          {Object.keys(LINES).map((line) => {
            if (LINES[line].notVisible) return null;

            return (
              <MenuItem
                key={line}
                onClick={() => {
                  setFilterProductLine(line);
                  handleClose();
                }}
                selected={filterProductLine === line}
              >
                {`${LINES[line].title} (${line})`}
              </MenuItem>
            );
          })}
        </Menu>
        <Button
          color="primary"
          onClick={(e) => handleClick("states", e)}
          disabled={!filterProductLine}
        >
          States
        </Button>
        <Menu
          anchorEl={anchorEl?.type === "states" ? anchorEl.target : null}
          open={anchorEl?.type === "states" ? true : false}
          onClose={handleClose}
        >
          {Object.keys(STATES).map((state) => {
            return (
              <MenuItem
                key={state}
                onClick={() => {
                  setFilterState(STATES[state].key);
                  handleClose();
                }}
                selected={filterState === STATES[state].key}
              >
                {`${STATES[state].title} (${STATES[state].key})`}
              </MenuItem>
            );
          })}
        </Menu>
      </React.Fragment>
    );
  }

  function getHighlight(item) {
    if (
      item.highlight &&
      item.highlight.text &&
      item.highlight.text.length > 0
    ) {
      let text = item.highlight.text.join("   ");
      if (text.length > 250) {
        text.substring(0, 250);
        text += "...";
      }
      return { __html: text };
    }
    return { __html: "" };
  }

  function getLineToolTip(item, line) {
    const lineName = LINES[line] ? LINES[line].title : "Line Unknown";
    if (item._source[`aais_${line}_Jur_ss`]) {
      const states = item._source[`aais_${line}_Jur_ss`];
      if (Array.isArray(states)) {
        return `${lineName} states (${states.length}): ${states.join(", ")}`;
      }

      return `${lineName} states (1): ${item._source[`aais_${line}_Jur_ss`]}`;
    }
    return `${lineName} state (0)`;
  }

  function getProductLines(item, showClose) {
    if (item._source.productLines_ss) {
      if (Array.isArray(item._source.productLines_ss)) {
        return item._source.productLines_ss.map((line) => {
          return (
            <Tooltip
              title={getLineToolTip(item, line)}
              key={`${item._id}${line}`}
            >
              <Chip
                variant="outlined"
                color="primary"
                size="small"
                label={line}
                onClick={() => {
                  setFilterProductLine(line);
                  setFilterState(null);
                }}
              />
            </Tooltip>
          );
        });
      }
      if (showClose) {
        return (
          <Chip
            key={`${item._source.productLines_ss}-line`}
            color="primary"
            size="small"
            label={item._source.productLines_ss}
            deleteIcon={
              <DeleteIcon
                data-test={`productLine-chip-delete-icon-${item._source.productLines_ss}`}
              ></DeleteIcon>
            }
            onDelete={() => {
              setFilterProductLine(null);
              setFilterState(null);
            }}
          />
        );
      }

      return (
        <Tooltip title={getLineToolTip(item, item._source.productLines_ss)}>
          <Chip
            key={`${item._source.productLines_ss}-line`}
            variant="outlined"
            color="primary"
            size="small"
            label={item._source.productLines_ss}
            onClick={() => setFilterProductLine(item._source.productLines_ss)}
          />
        </Tooltip>
      );
    }
  }

  function getStatus(item, showClose) {
    let label = "";
    let icon;
    switch (item._source.status_s) {
      case "Externally Approved": {
        label = "Current";
        icon = <CurrentIcon className={classes.currentIcon}></CurrentIcon>;
        break;
      }
      case "Superseded": {
        label = "Not Current";
        icon = <NotCurrent className={classes.notCurrentIcon}></NotCurrent>;
        break;
      }
      default: {
        label = "N/A";
      }
    }
    if (showClose) {
      return (
        <Chip
          key={`${label}-status`}
          icon={icon}
          color="primary"
          size="small"
          label={label}
          deleteIcon={
            <DeleteIcon
              data-test={`status-chip-delete-icon-${item._source.status_s}`}
            ></DeleteIcon>
          }
          onDelete={() => setFilterStatus(null)}
        />
      );
    }

    return (
      <Chip
        key={`${label}-status`}
        icon={icon}
        variant="outlined"
        color="primary"
        size="small"
        label={label}
        onClick={() => setFilterStatus(item._source.status_s)}
      />
    );
  }

  function getStates(item, showClose) {
    //no line selected. need to select product first
    if (!filterProductLine) return;

    if (item._source[`aais_${filterProductLine}_Jur_ss`]) {
      const states = item._source[`aais_${filterProductLine}_Jur_ss`];
      if (Array.isArray(states)) {
        return states.map((state) => {
          return (
            <Chip
              key={`${item._id}${filterProductLine}${state}`}
              variant="outlined"
              color="primary"
              size="small"
              label={state}
              onClick={() => setFilterState(state)}
            />
          );
        });
      }

      const state = item._source[`aais_${filterProductLine}_Jur_ss`];
      if (showClose) {
        return (
          <Chip
            key={`${state}-state`}
            color="primary"
            size="small"
            label={state}
            deleteIcon={
              <DeleteIcon
                data-test={`state-chip-delete-icon-${state}`}
              ></DeleteIcon>
            }
            onDelete={() => setFilterState(null)}
          />
        );
      }

      return (
        <Chip
          key={`${state}-state`}
          variant="outlined"
          color="primary"
          size="small"
          label={state}
          onClick={() => setFilterState(state)}
        />
      );
    }
  }

  function getStateCount(item) {
    if (item._source[`aais_${filterProductLine}_Jur_ss`]) {
      const states = item._source[`aais_${filterProductLine}_Jur_ss`];
      if (Array.isArray(states)) {
        return states.length;
      }

      return 1;
    }

    return 0;
  }

  function getFormInfo(item) {
    let label = "";

    if (item._source.packageType_s === "PFM") {
      label = `${item._source.documentNumber_s}${item._source.formEdition_s}`;
    } else if (
      item._source.packageType_s === "PLC" ||
      item._source.packageType_s === "PRI" ||
      item._source.packageType_s === "PRL"
    ) {
      label = `${item._source.documentNumber_s}-${item._source.manualRevision_s}`;
    }

    return label;
  }

  function getTypeIcon(packageType_s) {
    switch (packageType_s) {
      case "PFM": {
        return <FormIcon></FormIcon>;
      }
      case "PLC": {
        return <LossCostIcon></LossCostIcon>;
      }
      case "PRI": {
        return <RatingIcon></RatingIcon>;
      }
      case "PRL": {
        return <RuleIcon></RuleIcon>;
      }
      case "SUP":
      case "IMS": {
        return <SupportingDocIcon></SupportingDocIcon>;
      }
      default: {
        return null;
      }
    }
  }

  function getType(item, showClose) {
    let label = "";
    let icon;
    switch (item._source.packageType_s) {
      case "PFM": {
        label = "Form";
        icon = getTypeIcon(item._source.packageType_s);
        break;
      }
      case "PLC": {
        label = "Loss Cost";
        icon = getTypeIcon(item._source.packageType_s);
        break;
      }
      case "PRI": {
        label = "Rating Info";
        icon = getTypeIcon(item._source.packageType_s);
        break;
      }
      case "PRL": {
        label = "Rules";
        icon = getTypeIcon(item._source.packageType_s);
        break;
      }
      case "SUP": {
        label = "Supporting Documents";
        icon = getTypeIcon(item._source.packageType_s);
        break;
      }
      case "IMS": {
        label = "Supporting Documents (IMG)";
        icon = getTypeIcon(item._source.packageType_s);
        break;
      }
      default: {
        label = "N/A";
      }
    }
    if (showClose) {
      return (
        <Chip
          key={`${label}-type`}
          icon={icon}
          color="primary"
          size="small"
          label={label}
          deleteIcon={
            <DeleteIcon
              data-test={`type-chip-delete-icon-${item._source.packageType_s}`}
            ></DeleteIcon>
          }
          onDelete={() => setFilterType(null)}
        />
      );
    }

    return (
      <Chip
        key={`${label}-type`}
        icon={icon}
        variant="outlined"
        color="primary"
        size="small"
        label={label}
        onClick={() => setFilterType(item._source.packageType_s)}
      />
    );
  }

  function getItems() {
    if (!results || !results.hits || !results.hits.hits) return;

    return results.hits.hits.map((item) => {
      return (
        <Grid container item key={item._id} className={classes.result}>
          <Grid container item xs={12}>
            <Typography
              color="primary"
              variant="h6"
              display="inline"
              className={classes.title}
              onClick={() => {
                setPreviewItem(item._source);
              }}
            >
              {`${item._source.title_s}`} - ({getFormInfo(item)})
            </Typography>
          </Grid>
          <Grid container item xs={12}></Grid>
          <Grid item xs={12}>
            <div
              className={classes.highlight}
              variant="body1"
              dangerouslySetInnerHTML={getHighlight(item)}
            ></div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.chipList}>
              {getStatus(item)}
              {getType(item)}
              {getProductLines(item)}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.chipList}>
              {filterProductLine && (
                <Tooltip title={getLineToolTip(item, filterProductLine)}>
                  <Chip
                    variant="outlined"
                    size="small"
                    label={`${filterProductLine} states (${getStateCount(
                      item
                    )})`}
                    deleteIcon={<StatesHelperIcon></StatesHelperIcon>}
                    onDelete={() => {}}
                  />
                </Tooltip>
              )}

              {getStates(item)}
            </div>
          </Grid>
        </Grid>
      );
    });
  }

  return (
    <React.Fragment>
      <Paper elevation={0} className={classes.paper}>
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                autoFocus
                autoCorrect="off"
                spellCheck="false"
                placeholder="Search"
                data-test="searchScreen"
                value={term}
                onChange={(event) => setTerm(event.target.value)}
                inputProps={{
                  onKeyDown: handlekeyDown,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {term && (
                        <IconButton
                          type="submit"
                          color="primary"
                          onClick={() => {
                            setTerm("");
                          }}
                          component="span"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        type="submit"
                        color="primary"
                        onClick={performSearch}
                        component="span"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              {getFilterBar()}
            </Grid>
            <Grid item xs={12}>
              <div className={classes.chipList}>{getCurrentFilters()}</div>
            </Grid>
            {results && results.hits && (
              <React.Fragment>
                <Grid item xs={12} className={classes.horizontalRule}>
                  <Typography className={classes.hits}>{`About ${
                    results.hits.total.value
                  } results (${convertMsToSeconds(
                    results.took
                  )} seconds)`}</Typography>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
          {loading && <LinearProgress></LinearProgress>}
          <Grid container>{getItems()}</Grid>
        </Container>
      </Paper>
      {previewItem && (
        <AAISPDFViewerDialog
          s3Key={previewItem.rendition_locations_ss}
          title={previewItem.title}
          handleClose={() => setPreviewItem()}
        ></AAISPDFViewerDialog>
      )}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    //paddingBottom: theme.spacing(4),
    backgroundColor: "white",
  },
  hits: {
    fontSize: 14,
    color: "#979797",
  },
  horizontalRule: {
    borderBottom: "1px solid #d4d4d4",
  },
  paper: {
    height: "100%",
  },
  highlight: {
    fontSize: 14,
    color: theme.palette.grey[600],
  },
  result: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  chipList: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.25),
    },
  },
  currentIcon: {
    color: "#4caf50",
  },
  notCurrentIcon: {
    color: "#dc004e",
  },
  title: {
    cursor: "pointer",
  },
}));
