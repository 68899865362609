import React, { useContext, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import SummaryBar from "../components/SummaryBar";
import Grid from "@material-ui/core/Grid";
import MeContext from "../contexts/MeContext";
import Typography from "@material-ui/core/Typography";
import LINES from "../enums/LINES";
import LineTabsSection from "../components/LineTabsSection";
import { Container, Paper, Chip } from "@material-ui/core";
import DownloadMaterialsCard from "../components/DownloadMaterialsCard";
import DownloadCSVMaterialsCard from "../components/DownloadCSVMaterialsCard";
import DownloadHistoryCard from "../components/DownloadHistoryCard";
import * as UserUtil from "../util/userUtil";
import CertifiedIcon from "@material-ui/icons/Check";
import NotCertifiedIcon from "@material-ui/icons/NotInterested";
import CertifyingIcon from "@material-ui/icons/ReportProblemOutlined";
import LineUpdates from "../components/LineUpdates";

function LineScreen(props) {
  const { classes } = props;
  const me = useContext(MeContext);
  const lineKey = props.match.params.lineKey;

  useEffect(() => {
    window.gtag("config", `${process.env.REACT_APP_GA}`, {
      page_title: "Line Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
    });
  }, [props.match.params.lineKey]);

  function getTitle() {
    if (!lineKey && LINES[lineKey]) return `N/A`;

    let status = "";
    let icon = null;
    let chipCss = null;
    if (LINES[lineKey].certificationStatus === "CERTIFIED") {
      status = "Certified";
      icon = <CertifiedIcon></CertifiedIcon>;
      chipCss = classes.certifiedChip;
    } else if (LINES[lineKey].certificationStatus === "CERTIFYING") {
      status = "Certifying";
      icon = <CertifyingIcon></CertifyingIcon>;
      chipCss = classes.certifyingChip;
    } else if (LINES[lineKey].certificationStatus === "NOT_CERTIFIED") {
      status = "Not Certified";
      icon = <NotCertifiedIcon></NotCertifiedIcon>;
      chipCss = classes.notCertifiedChip;
    }
    return (
      <React.Fragment>
        <Typography variant="h5" display="inline">
          {LINES[lineKey].title} ({lineKey})
        </Typography>

        <Chip
          className={chipCss}
          label={status}
          icon={icon}
          data-test="status"
        />
      </React.Fragment>
    );
  }

  function getDescription() {
    if (!lineKey && LINES[lineKey]) return `N/A`;
    return `${LINES[lineKey].description}`;
  }

  function getWelcome() {
    return (
      <React.Fragment>
        <Container>
          <Paper className={classes.lineDescription}>
            <Typography data-test="lineDescription" variant="h6">
              {getDescription()}
            </Typography>
          </Paper>
        </Container>
      </React.Fragment>
    );
  }

  function getMaterialsTab() {
    return (
      <React.Fragment>
        <Container className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <DownloadMaterialsCard lineKey={lineKey}></DownloadMaterialsCard>
            </Grid>
            <Grid item xs={12} md={6}>
              {me?.userId && (
                <DownloadHistoryCard
                  lineKey={lineKey}
                  userId={me.userId}
                ></DownloadHistoryCard>
              )}
            </Grid>
            {me && UserUtil.isEmployee(me) && (
              <Grid item xs={12} md={6}>
                <DownloadCSVMaterialsCard
                  lineKey={lineKey}
                ></DownloadCSVMaterialsCard>
              </Grid>
            )}
          </Grid>
        </Container>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <SummaryBar expanded={false} title={getTitle()}>
        {getWelcome()}
      </SummaryBar>
      <LineTabsSection
        materialsTab={getMaterialsTab()}
        updatesTab={<LineUpdates lineKey={lineKey}></LineUpdates>}
      />
    </React.Fragment>
  );
}

const styles = (theme) => ({
  grid: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  lineDescription: {
    padding: theme.spacing(4),
  },
  certifiedChip: {
    backgroundColor: "#03960e",
    color: "white",
    marginLeft: 10,
  },
  certifyingChip: {
    backgroundColor: "#f7f4a4f0",
    color: "black",
    marginLeft: 10,
  },
  notCertifiedChip: {
    backgroundColor: "white",
    color: "black",
    marginLeft: 10,
  },
});

export default withStyles(styles, { withTheme: true })(LineScreen);
