/**
 * @description HealthStatusCheckApi Module. This will have all the API's needed
 * to interact with the backend API's for the HealthStatusCheck
 * @module apis/HealthStatusCheckApi
 * @since 1.0.0
 * @author Reza Khazali <rezak@aaisonline.com>
 * @public
 */

import { API } from "aws-amplify";

/**
 * @description Checks Login Functionality
 * @since 1.0.0
 * @author Reza Khazali <rezak@aaisonline.com>
 */

// checklogin
export async function checkLoginHealth(name) {
  const path = `/login-health-check`;

  try {
    await API.get("HEALTHCHECK", path);
    console.log("checkLoginHealth");
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function checkDownloadHealth(name) {
  const path = `/download-material-health-check`;

  try {
    await API.get("HEALTHCHECK", path);
    console.log("checkDownloadFunc");
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
